<template>
    <div class="edit">
        <div class="form">
            <div class="item">
                <div class="lable">分类名</div>
                <div class="content"><el-input v-model="form.name"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">描述</div>
                <div class="content"><el-input v-model="form.reason"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">活动时间</div>
                <div class="content">
                    <el-date-picker
                        v-model="hotDate"
                        :unlink-panels="true"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="{ disabledDate }"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="item">
                <div class="lable">范围标题</div>
                <div class="content"><el-input v-model="form.title"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">适用范围</div>
                <div class="content" style="flex-wrap: wrap">
                    <el-tag :key="tag" v-for="tag in tagList" type="info" closable @close="close(tag)">
                        {{ tag }}
                    </el-tag>
                    <el-input
                        v-model="tagIpt"
                        size="small"
                        style="width: 120px; margin-left: 10px"
                        placeholder="添加适用范围"
                        @keydown.enter.native="tagAdd"
                        @blur="tagAdd"
                    ></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">活动状态</div>
                <div class="content">
                    <el-switch
                        v-model="form.hot_status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                    >
                    </el-switch>
                    <el-input v-model="form.hot" v-show="form.hot_status"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable" style="height: 148px; line-height: 148px">背景图</div>
                <div class="content">
                    <el-upload
                        :headers="{ token: token }"
                        :limit="1"
                        name="thumbnail"
                        :action="imgBaseUrl + '/api/Project/upload'"
                        list-type="picture-card"
                        :multiple="false"
                        :on-success="success"
                        :file-list="fileList"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
            <div class="item">
                <div class="lable">原价</div>
                <div class="content"><el-input v-model="form.oldprice"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">现价</div>
                <div class="content"><el-input v-model="form.newprice"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">状态</div>
                <div class="content">
                    <el-switch
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                    >
                    </el-switch>
                </div>
            </div>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="取消" scale="4" @click.native="no"></VyIcon>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import Cookies from "js-cookie";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { VyIcon },
    data() {
        return {
            form: {
                name: "",
                reason: "",
                title: "",
                content: "",
                bg_img: "",
                oldprice: "",
                newprice: "",
                status: true,
                hot: "",
                hot_status: false,
                hot_start_time: "",
                hot_end_time: "",
            },
            hotDate: [],
            tagList: [],
            tagIpt: "",
            token: "",
            fileList: [],
            baseURL: "",
        };
    },
    mounted() {
        this.token = Cookies.get("token");
    },
    created() {
        this.baseURL = axios.defaults.baseURL;
    },
    methods: {
        // 提交
        async yes() {
            let { data } = await axios.post(
                "/api/price/fadd",
                qs.stringify({
                    ...this.form,
                    status: Number(this.form.status),
                    hot_status: Number(this.form.hot_status),
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
                return;
            } else {
                this.$message.success(data.msg);
            }
            this.form = {
                name: "",
                reason: "",
                title: "",
                content: "",
                bg_img: "",
                oldprice: "",
                newprice: "",
                status: true,
                hot: "",
                hot_status: false,
                hot_start_time: "",
                hot_end_time: "",
            };
            this.no();
        },
        // 取消
        no() {
            this.form = {
                name: "",
                reason: "",
                title: "",
                content: "",
                bg_img: "",
                oldprice: "",
                newprice: "",
                status: false,
                hot: "",
                hot_status: false,
                hot_start_time: "",
                hot_end_time: "",
            };
            this.hotDate = [];
            this.tagList = [];
            this.fileList = [];
            this.$emit("stop");
        },
        // 添加描述
        tagAdd(e) {
            if (!this.tagIpt) return e.target.blur();
            this.tagList.push(this.tagIpt);
            this.tagIpt = "";
            if (e.type === "keydown") e.target.blur();
        },
        // 清除一项描述
        close(tag) {
            this.tagList.splice(this.tagList.indexOf(tag), 1);
        },
        // 图片上传成功后
        success(url) {
            this.form.bg_img = axios.defaults.baseURL + url;
        },
        disabledDate(time) {
            return time.getTime() < Date.now();
        },
    },
    computed: {},
    watch: {
        hotDate: {
            deep: true,
            handler(newVal) {
                if (newVal.length === 0) return;
                let arr = newVal.map(e => {
                    return `${e.getFullYear()}-${e.getMonth() + 1}-${e.getDate()}`;
                });
                this.form.hot_start_time = arr[0];
                this.form.hot_end_time = arr[1];
            },
        },
        tagList: {
            deep: true,
            handler(newVal) {
                this.form.content = newVal.join("-");
            },
        },
        "form.hot_status": {
            handler(newVal) {
                if (newVal === false) {
                    this.form.hot = "";
                }
            },
        },
    },
};
</script>
<style scoped lang="less">
.edit {
    .form {
        // background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;

                /deep/.el-input__inner {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
